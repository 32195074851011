





















import { Component, Mixins } from 'vue-property-decorator'

import { Loader } from '../../../front/shared/molecules/Loader'
import { ProductCard } from '../../../front/products/organisms/ProductCard'
import { StructureConfigurable } from '../../../support/mixins'
import { SuggestedProductsMixin } from '../../../front/shared/mixins/suggestedProducts.mixin'
import {
  translateProductToProductCard
} from '../../../front/products/organisms/ProductCard/ProductCard.helpers'

import { AbstractModuleUi } from '../../abstract/ui'

import {
  GLOBAL_CONTAINER_WIDTH,
  PRODUCTS_TEASER_COMPONENT_KEY,
  PRODUCTS_TEASER_DEFAULT_CONFIG
} from '../ProductsTeaser.config'
import { ProductsTeaserModule } from '../ProductsTeaser.contracts'

/**
 * Container component for the `ProductsTeaserModuleUi`.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<ProductsTeaserModuleUi>({
  name: 'ProductsTeaserModuleUi',
  components: { Loader, ProductCard },
  async created (): Promise<void> {
    this.config = this.getComponentConfig(
      PRODUCTS_TEASER_COMPONENT_KEY,
      PRODUCTS_TEASER_DEFAULT_CONFIG
    )

    if (this.isMobile()) {
      this.defaultWidth = `${(100 / this.productsPerPage.mobile)}vw`
    } else {
      this.defaultWidth = `${(100 / this.productsPerPage.desktop)}%`
    }

    if (this.shouldFetchSuggestedProducts && this.automationMode) {
      await this.loadSuggestedProducts(this.data.content.sku, this.automationMode)
    }
  }
})
export class ProductsTeaserModuleUi extends Mixins<AbstractModuleUi<ProductsTeaserModule>, StructureConfigurable, SuggestedProductsMixin>(
  AbstractModuleUi,
  StructureConfigurable,
  SuggestedProductsMixin
) {
  public defaultWidth = 'auto'

  /**
   * Defines typeof automation
   */
  public get automationMode (): string | undefined {
    if (this.data.source && this.data.source.options?.mode) {
      return this.data.source.options.mode
    }

    return undefined
  }

  public get carouselConfig () {
    return {
      ...this.config.carouselConfig,
      responsive: {
        ...this.config.carouselConfig.responsive,
        desktop: {
          ...this.config.carouselConfig.responsive.desktop,
          perPage: this.productsPerPage.desktop
        }
      }
    }
  }

  /**
   * Determines whether module has customClasses
   */
  public get customClass (): Array<string> {
    if (!this.data.content.customClass || this.data.content.customClass.length === 0) {
      return []
    }
    return this.data.content.customClass
  }

  /**
   * Products teaser heading
   */
  public get heading () {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }

  /**
   * Products teaser content
   */
  public get products () {
    if (this.shouldFetchSuggestedProducts) {
      return this.suggestedProducts ?? []
    }

    if (!this.content.products || this.content.products.length === 0) {
      return []
    }

    return this.content.products.map(p => translateProductToProductCard(p))
  }

  /**
   * Determines amount of products per carousel page
   */
  public get productsPerPage () {
    return {
      mobile: this.config.carouselConfig.responsive.mobile.perPage,
      desktop: this.content.productsPerPage ??
        this.config.carouselConfig.responsive.desktop.perPage
    }
  }

  /**
   * Determines product card image width based on (container width / slides amount).
   */
  public get productCardImageWidth (): number {
    const device = this.isMobile() ? 'mobile' : 'desktop'
    return (GLOBAL_CONTAINER_WIDTH[device] / this.productsPerPage[device])
  }

  /**
   * Determines whether products in carousel should be automatically fetched (recommendations)
   */
  public get shouldFetchSuggestedProducts (): boolean {
    return this.data.source && this.data.source.options?.sku && this.data.content.sku
  }
}

export default ProductsTeaserModuleUi
